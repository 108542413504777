export const ArrowLeftIcon = '/icons/arrow-left.svg';
export const ArrowLeftIconDark = '/icons/arrow-left-dark.svg';

export const ArrowLeftWhite = '/arrow-left-white.svg';

export const ShareClipIcon = '/preview/share-clip.svg';
export const ShareClipHoveredIcon = '/preview/share-clip-hovered.svg';

export const SupportBannerBg = '/pricing/pricing-page-support-banner-bg.png';
export const SupportBannerBgSm =
  '/pricing/pricing-page-support-banner-bg-sm.png';
